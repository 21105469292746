'use strict';

function removeZoom() {
	// Clear any existing zoom elements
	$('.slick-current [data-zoom-image]').removeData('elevateZoom');
	$('.zoomContainer').remove();
}

function enableZoom() {
	removeZoom();
	
	// Zoom is only enabled for non-mobile views
	if (window.innerWidth >= 795) {
		var $currentZoomSlide = $('.slick-current [data-zoom-image]');
		
		if ($currentZoomSlide.length > 0) {
			$currentZoomSlide.elevateZoom({
				zoomType: "inner",
				cursor: "crosshair",
				zoomWindowFadeIn: 100,
				zoomWindowFadeOut: 100,
				loadingIcon: "True"
			});
		}
	}
}

function loadZoomImage(slideIndex) {
	// Zoom is only enabled for non-mobile views
	if (window.innerWidth >= 795) {
		var zoomImage = $('.primary-image-carousel .slick-slide[data-slick-index=' + slideIndex + '] img').data('zoom-image');
		
		if (zoomImage) {
			$('.primary-images').addClass('zoom-loading');
			$('<img />').attr('src', zoomImage).on('load', function() {
				$(this).remove();
				$('.primary-images').removeClass('zoom-loading');
			});
		}
	}
}

module.exports = {

	initialize: function () {
		// Reinitialize zoom on window resize
		var throttleResize;
		$(window).resize(function() {
			clearTimeout(throttleResize);
			throttleResize = setTimeout(function() {
		        enableZoom();
		    }, 100);
		});
		
		$('.primary-image-carousel').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			loadZoomImage(nextSlide);
		});
		
		$('.primary-image-carousel').on('afterChange', function(event, slick, currentSlide) {
			enableZoom();
		});
		
		$('.primary-image-carousel').on('init', function (slick) {
			$('body').trigger('product:afterCarouselInit', this);
		});
	},
	
	enable: function () {
		enableZoom();
	}
	
};
