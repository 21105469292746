'use strict';

module.exports = {
	
	initPosition: function () {
		var $gtmContainers = $('.gtm-container:not(.gtm-container-initialized)');
		
		$gtmContainers.each(function () {
			var $currentContainer = $(this),
				$gtmProducts = $currentContainer.find('.gtmproduct'),
				containerIndexStart = $currentContainer.data('page-size') * ($currentContainer.data('page-number'));  // Paging starts at 0
			
			$currentContainer.addClass('gtm-container-initialized');
			
			// Register the index position of the GTM products inside each GTM container.
			$gtmProducts.each(function () {
				var $currentProduct = $(this);
				$currentProduct.data('position', $gtmProducts.index($currentProduct) + containerIndexStart + 1);

				if ($currentProduct.parents('.grid-tile').first().find('.price [itemprop=mapmessage]').length > 0) {
					$currentProduct.data('price', 0.00);
				}
				else {
					var $price = $currentProduct.parents('.grid-tile').first().find('.adjusted-price').data('productPrice');
					$currentProduct.data('price', $price);
				}

			});

			$('span[itemprop="price"]').each(function() {
				var priceValue = $(this).attr('content');
				if ( priceValue.endsWith('.98') ) {
					$(this).parent('.sales').addClass('strike-price-available');
					$(this).parent('.sales').prev().addClass('strike-price-available');
				}
			});

		});
	}

};