'use strict';

function gtmproduct(data){
	var gtmdata = [];
	for(var x=0; x < data.order.items.items.length; x++){
		gtmdata.push({
			id : data.order.items.items[x].masterID,
			name : setReg(data.order.items.items[x].productName),
			price : data.order.items.items[x].price.sales.decimalPrice,
			brand : data.order.items.items[x].brand != null ? setReg(data.order.items.items[x].brand) : '',
			category : setReg(data.order.items.items[x].categoryFullPath),
			variant: setVariant(data.order.items.items[x].variationAttributes[0] != null ? data.order.items.items[x].variationAttributes[0].displayValue : '','c') + setVariant(data.order.items.items[x].variationAttributes[1] != null ? data.order.items.items[x].variationAttributes[1].displayValue : null,'s') + setVariant(data.order.items.items[x].variationAttributes[2] != null ? data.order.items.items[x].variationAttributes[2].displayValue : '','w'),
			quantity : data.order.items.items[x].quantity
		});
	}
	return gtmdata;
}

function gtmPrice(data){
	var mapType = data.mapType;
	if (mapType && data.mapFirstRevealOurPrice) {
        if (mapType === 'B' || mapType === 'C') {
            return 0.00;
        }
    }
    return data.price.sales.value;
}
function setReg(data){
	// Decode all HTML entities in the given parameter.
	var temp = document.createElement('div');
	temp.innerHTML = data;
	return temp.childNodes.length === 0 ? '' : temp.childNodes[0].nodeValue;
}

function convertHalfSize(size){
	var sizetoConvert = size.toString(),
		halfsize = sizetoConvert.match(/\½/i) ? true : false;
	
	if(halfsize){
		halfsize = sizetoConvert.replace(/\½/g, '');
		return (parseInt(halfsize) + .5);
	} else {
		return size;
	}
}

function setVariant(data,type){
	var result = '';
	
	switch(type) {
	
	    case 'c':
	    	if(data != null && data.length > 0){
				result = "Color:" + data;
			}
	        break;
	        
	    case 's':
	    	if(data != null){
				result = ",Size:" + convertHalfSize(data);
			}
	        break;
	        
	    case 'w':
			if(data != null && data.length > 0){
				result = ",Width:" + data;
			}
	        break;
	        
	    default:
	    	result = '';
	}
	
	return result;
}


module.exports = {
		
	    MiniCartIncreaseQty: function (data) {
	    	try {
		    	ssDataLayer.push({
		    		  'event': 'ecom.cart.add',
		    		  'ecommerce': {
		    		    'add': {
		    		      'products': [
		    		       {
                                'id': data.masterid,
                                'name': setReg(data.name),
                                'price': data.price,
                                'brand': setReg(data.brand),
                                'category': setReg(data.category),
                                'variant': setVariant(data.color,'c') + setVariant(data.size,'s') + setVariant(data.width,'w'),
                                'quantity': eval(data.newQty +"-"+ data.oldQty)
		    		       }]
		    		    }
		    		  }
		    		});
	    	}
	    	catch(ex) {
	    		console.log('GTM-MiniCartIncreaseQty Error:' + ex);
	    	}
	    },
	    
	    MiniCartDecreaseQty: function (data) {
	    	try {
		    	ssDataLayer.push({
		    		  'event': 'ecom.cart.remove',
		    		  'ecommerce': {
		    		    'remove': {
		    		      'products': [
		    		       {
                                'id': data.masterid,
                                'name': setReg(data.name),
                                'price': data.price,
                                'brand': setReg(data.brand),
                                'category': setReg(data.category),
                                'variant': setVariant(data.color,'c') + setVariant(data.size,'s') + setVariant(data.width,'w'),
                                'quantity': eval(data.oldQty +"-"+ data.newQty)
		    		       }]
		    		    }
		    		  }
		    		});
	    	}
	    	catch(ex) {
	    		console.log('GTM-MiniCartDecreaseQty Error:' + ex);
	    	}
	    },
	    
	    RemoveLineItemfromCart: function (data) {
	    	try {
		    	ssDataLayer.push({
		    		  'event': 'ecom.cart.remove',
		    		  'ecommerce': {
		    		    'remove': {
		    		      'products': [
		    		       {
		    		    	   'id': data.masterid,
			    				'name': setReg(data.name),
			    		        'price': data.price,
			    		        'brand': setReg(data.brand),
			    				'category': setReg(data.category),
			    				'variant': setVariant(data.color,'c') + setVariant(data.size,'s') + setVariant(data.width,'w'),
			    			    'quantity': data.qty
		    		       }]
		    		    }
		    		  }
		    		});
	    	}
	    	catch(ex) {
	    		console.log('GTM-MiniCartDecreaseQty Error:' + ex);
	    	}
	    },
	    
	    EmailSignUp: function(email){
	    	try {
		    	ssDataLayer.push({
		    		'event':'signup.newsletter.footer',
		    		'email': email
		    	});
	    	}
	    	catch(ex) {
	    		console.log('GTM-MiniCartDecreaseQty Error:' + ex);
	    	}
	    },
	    
	    ProductCarousel: function(data){
	    	try {
		    	ssDataLayer.push({
		    		  'event':'ecom.product.list',
		    		  'ecommerce': {
		    		    'impressions': data
		    		  }
		    		});
	    	}
	    	catch(ex) {
	    		console.log('GTM-ProductCarousel Error:' + ex);
	    	}
	    },
	    
	    PDP: function(data){
	    	try {
				var avail = '';
				if(data && data.availability && data.availability.status)
				{
					avail = data.availability.status;
				}
		    	ssDataLayer.push({
		    		  'event':'ecom.product.detail',
		    		  'ecommerce': {
		    			  'detail': { 
			    		    'products': [
			    		       {
			    		         'id': data.id,
			    		         'name': setReg(data.productName),
			    		         'price': gtmPrice(data),
			    		         'brand': setReg(data.brand),
								 'category': setReg(data.categoryFullPath),
								 'availability': avail
			    		       }]
		    			  }
		    		  }
		    		});
	    	}
	    	catch(ex) {
	    		console.log('GTM-ProductCarousel Error:' + ex);
	    	}
	    },
	    
	    AddToCart: function(data){
	    	try {
		    	ssDataLayer.push({
		    		  'event': 'ecom.cart.add',
		    		  'ecommerce': {
		    		    'add': {
		    		      'products': [
		    		       {
		    		         'id': data.masterid,
		    		         'name': setReg(data.productName),
		    		         'price': gtmPrice(data),
		    		         'brand': setReg(data.brand),
		    		         'category': setReg(data.categoryFullPath),
		    		         'variant': setVariant(data.color,'c') + setVariant(data.size,'s') + setVariant(data.width,'w'),
		    		         'quantity': data.qty
		    		       }]
		    		    }
		    		  }
		    		});
	    	}
	    	catch(ex) {
	    		console.log('GTM-AddToCart Error:' + ex);
	    	}
	    },
	    
	    ShippingOption: function(data){
	    	try {
		    	ssDataLayer.push({
		    		  'event': 'ecom.checkout.shipping.validation',
		    		  'ecommerce': {
		    		    'checkout': {
		    		      'actionField': {'step': 3, 'option': data.shippingMethod}
		    		   }
		    		 }
		    	});
	    	}
	    	catch(ex) {
	    		console.log('GTM-ShippingOption:' + ex);
	    	}
	    },

		ProceedToPayment: function(data){
	    	try {
		    	ssDataLayer.push({
		    		  'event': 'ecom.checkout.shipping.completeStep',
		    		  'ecommerce': {
		    		    'checkout': {
		    		      'actionField': {'step': 3, 'option': data.shippingMethod}
		    		   }
		    		 }
		    	});
	    	}
	    	catch(ex) {
	    		console.log('GTM-ShippingOption:' + ex);
	    	}
	    },
	    
	    Payment: function(data){
	    	try {
		    	ssDataLayer.push({
		    		  'event': 'ecom.checkout.payment',
		    		  'ecommerce': {
		    		    'checkout': {
		    		      'actionField': {'step': 4},
		    		      'products': gtmproduct(data)
		    		   }
		    		 }
		    	});
	    	}
	    	catch(ex) {
	    		console.log('GTM-Payment:' + ex);
	    	}
	    },
	    
	    PaymentOption: function(data){
	    	try {
		    	ssDataLayer.push({
		    		  'event': 'ecom.checkout.payment.completeStep',
		    		  'ecommerce': {
		    		    'checkout': {
		    		      'actionField': {'step': 4, 'option': data.paymentMethod.value}
		    		   }
		    		 }
		    	});
	    	}
	    	catch(ex) {
	    		console.log('GTM-PaymentOption:' + ex);
	    	}
	    },
	    
	    OrderReview: function(data){
	    	try {
		    	ssDataLayer.push({
		    		  'event': 'ecom.checkout.review',
		    		  'ecommerce': {
		    		    'checkout': {
		    		      'actionField': {'step': 5},
		    		      'products': gtmproduct(data)
		    		   }
		    		 }
		    	});
	    	}
	    	catch(ex) {
	    		console.log('GTM-OrderReview:' + ex);
	    	}
	    },

		PlaceOrder: function(data) {
			try {
		    	ssDataLayer.push({
		    		  'event': 'ecom.checkout.review.completeStep',
		    		  'ecommerce': {
		    		    'checkout': {
		    		      'actionField': {'step': 5}
		    		      // 'products': gtmproduct(data)
		    		   }
		    		 }
		    	});
	    	}
	    	catch(ex) {
	    		console.log('GTM-OrderReview:' + ex);
	    	}
		},
	    
	    StoreLocator: function(data){
	    	try {
		    	ssDataLayer.push({
		    		'event':'search.locations',
		    		'zipCode': data.searchKey.postalCode
		    	});
	    	}
	    	catch(ex) {
	    		console.log('GTM-StoreLocator:' + ex);
	    	}
	    },
	    
	    PromotionalBanners : function(){
	    	
		        try{
					var promotions = [];
					$.each($('.gtmpromo'), function(k,v) {
						var $this = $(this);
						promotions.push({
							'id' : $this.data('prid'),
							'name' : $this.data('prname'),
							'creative' : $this.data('prcreative'),
							'position' : $this.data('prposition')
						});
					});
					
					if (promotions.length > 0) {
						ssDataLayer.push({
							'event':'ecom.promo.view',
							'ecommerce': {
								'promoView': {
									'promotions': promotions
								}
							}
						});
					}
			} catch(ex){
				console.log('${GTM-PromotionalBanners} Error:' + ex);
			}
	    },
	    
	    PromotionClickHandler : function(){
	        try{
				$('.gtmpromo').click(function() {
					var $this = $(this);
					ssDataLayer.push({
				    	'event': 'ecom.promo.click',
					    'ecommerce': {
							'promoClick': {
								'promotions': [
									{
										'id': $this.data('prid'),
										'name': $this.data('prname'),
										'creative': $this.data('prcreative'),
										'position': $this.data('prposition')
									}
								]
							}
						}
					});
				});
		} catch(ex){
			console.log('GTM-PromotionClickHandler Error:' + ex);
		}
    }
};