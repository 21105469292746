'use strict';

var processInclude = require('./util');
var setdatalayer = require('./gtm/setdatalayer');
var gtmContainer = require('./gtm/container');

$(document).ready(function () {
    processInclude(require('./product/quickView'));
    gtmContainer.initPosition();
    
    var gtmdata = [];
    try {
	    $( ".gtmproduct" ).each(function() {
	        var data = $( this ).data('object'),
	        	productName = data.name == null ? '' : data.name.replace(/\&#39;/g, "'"),
	        	category = data.category == null ? '' : data.category.replace(/\&#39;/g, "'"),
	        	brand = data.brand == null ? '' : data.brand.replace(/\&#39;/g, "'"),
	        	availability = data.availability == null ? '' : data.availability.replace(/\&#39;/g, "'"),
	        	list = data.list == null ? '' : data.list.replace(/\&#39;/g, "'");
	        
			gtmdata.push({
				id : data.id,
				name : productName,
				price: $(this).data('price'),
				brand: brand,
				category: category,
				position: $(this).data('position'),
				list: data.view + list,
				availability: availability
			});
	    });
	    setdatalayer.ProductCarousel(gtmdata);
    } 
    catch(ex) {
		console.log('GTM-Error:' + ex);
	}
});
